<template>
  <v-container class="p-0">
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-card class="mx-auto mb-4 pt-0" outlined>
      <v-container class="pt-0">
        <v-row>
          <v-col
            cols="12"
            class="pr-0 pt-4 pb-0"
            max-width="100%
              "
          >
            <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
          </v-col>
          <v-col
            cols="12"
            md="12"
            align="right"
            align-self="center"
            class="py-0 mt-2 mb-2"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="toggleFiltersSelected"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition mode="out-in">
          <v-form
            v-show="showFilters"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="loadTopesCoberturaEspecial()"
            v-model="isFormValid"
          >
            <!-- Filtros -->
            <v-row>
              <!-- cobertura -->
              <v-col cols="4" class="py-0 fill-height" md="4">
                <v-text-field
                  v-model.trim="coberturaEspecialSelect"
                  type="text"
                  outlined
                  dense
                  clearable
                  disabled
                  label="Cobertura especial"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Convenios -->
              <v-col cols="12" class="py-0" md="3">
                <v-autocomplete
                  v-model="conveniosSelect"
                  :items="conveniosItems"
                  label="Convenio"
                  item-text="value"
                  item-value="id"
                  outlined
                  clearable
                  dense
                  @change="setPlanesByConvenios()"
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <!-- planes -->
              <v-col cols="12" md="3" class="py-0">
                <v-autocomplete
                  v-model="planesSelected"
                  :items="planesItems"
                  item-text="value"
                  item-value="id"
                  label="Plan"
                  outlined
                  clearable
                  dense
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <!-- Nomenclador -->
              <v-col cols="4" md="3" class="py-0">
                <v-autocomplete
                  v-model="nomencladorSelected"
                  :items="nomencladorItems"
                  item-text="value"
                  item-value="id"
                  autocomplete="on"
                  label="Nomenclador"
                  outlined
                  clearable
                  dense
                  return-object
                  :rules="codigoSelect ? rules.required : []"
                >
                </v-autocomplete>
              </v-col>
              <!-- codigo -->
              <v-col cols="4" md="3" class="py-0">
                <v-text-field
                  v-model.trim="codigoSelect"
                  type="text"
                  outlined
                  clearable
                  dense
                  label="Código de práctica"
                  v-mask="'#########'"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pr-4 pl-4" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    size="28"
                    @click="resetForm"
                  >
                    {{ clearFiltersIcon }}
                  </v-icon>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                elevation="2"
                small
                type="submit"
                form="filters-form"
                :disabled="!isFormValid"
              >
                Aplicar
              </v-btn>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsTopesPorCoberturas"
        class="elevation-1"
        :loading="isLoading"
        item-key="tipoCobTopeId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" align="end" v-if="canCreate">
                <v-btn color="primary" @click="openModal()" class="to-right">
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar tope</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteTope(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar tope</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6" md="7" align="right" class="py-3">
        <v-tooltip top max-width="40%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="fontsize"
              v-bind="attrs"
              outlined
              :disabled="itemsTopesPorCoberturas.length == 0"
              @click="exportExcelModelo"
            >
              Exportar detalle
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- Modal para editar -->
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="55%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTopesCoberturaEspecial
        :TopecoberturaEspecialObj="TopecoberturaEspecialObj"
        :coberturaObjectNomId="coberturaObjectNomId"
        :itemsTopesPorCoberturas="itemsTopesPorCoberturas"
        @closeAndReload="closeAndReload"
      ></EditTopesCoberturaEspecial>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'32%'"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditTopesCoberturaEspecial from "@/components/modules/afiliaciones/afiliados/EditTopesCoberturaEspecial.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "TopesCoberturaEspecial",
  directives: { mask },
  components: {
    GoBackBtn,
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    EditTopesCoberturaEspecial
  },
  data: () => ({
    rules: rules,
    routeToGo: "TipoCoberturaEspecial",
    title: enums.titles.TOPES_POR_TIPO_COBERTURA,
    titleDelete: enums.titles.DELETE_TOPE_POR_TIPO_COBERTURA,
    searchIcon: enums.icons.SEARCH,
    optionCode: enums.webSiteOptions.TOPES_COBERTURAS_ESPECIALES,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    search: "",
    coberturaEspecialObject: null,
    coberturaObjectNomId: null,
    coberturaEspecialSelect: "",
    isFormValid: false,
    nomencladorSelected: null,
    nomencladorItems: [],
    conveniosSelect: null,
    conveniosItems: [],
    planesSelected: null,
    planesItems: [],
    codigoSelect: null,
    canCreate: false,
    canDelete: false,
    canEdit: false,
    openModalEdit: false,
    showDeleteModal: false,
    showFilters: true,
    isLoading: false,
    loadingDeleteBtn: false,
    itemsTopesPorCoberturas: [],
    filtersApplied: [],
    paramObjectTope: null,
    headers: [
      {
        text: "Convenio",
        align: "start",
        value: "os.value",
        sortable: false
      },
      {
        text: "Plan",
        align: "start",
        value: "plan.value",
        sortable: false
      },
      {
        text: "Sexo",
        align: "center",
        value: "sexo",
        sortable: false
      },
      {
        text: "Edad desde",
        value: "edadDesde",
        align: "end",
        sortable: false
      },
      {
        text: "Edad hasta",
        value: "edadHasta",
        align: "end",
        sortable: false
      },

      {
        text: "Nomenclador",
        value: "nomenclador.value",
        sortable: false
      },
      {
        text: "Código desde",
        value: "codigoDesde",
        sortable: false
      },
      {
        text: "Código hasta",
        value: "codigoHasta",
        sortable: false
      },
      {
        text: "Agrupación de práctica",
        value: "agruPraNombre",
        sortable: false
      },
      {
        text: "Grupo de práctica",
        value: "grupoNombre",
        sortable: false
      },
      {
        text: "Vigencia",
        value: "vigencia",
        sortable: false
      },
      {
        text: "Topes",
        value: "tope",
        align: "end",
        sortable: false
      },
      {
        text: "Días",
        value: "dias",
        align: "end",
        sortable: false
      },
      {
        text: "Tipo de autorización",
        value: "ta.value",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ]
  }),
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.$route.params.coberturaEspecialItem) {
      this.coberturaEspecialObject = this.$route.params.coberturaEspecialItem;
    }
    this.loadTopesCoberturaEspecial();
    this.setSelects();
  },
  mounted() {
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getPermissionsTopesCoberturasEspeciales:
        "afiliaciones/getPermissionsTopesCoberturasEspeciales",
      getTopesPorTipoCoberturaEspecial:
        "afiliaciones/getTopesPorTipoCoberturaEspecial",
      deleteTopePorTipoCoberturaEspecial:
        "afiliaciones/deleteTopePorTipoCoberturaEspecial",
      getConvenios: "aportes/fetchConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      getPlanesTaxonomy: "configAfiliaciones/getPlanesTaxonomy",
      getNomencladores: "afiliaciones/getNomencladores",
      setAlert: "user/setAlert"
    }),
    async setPermisos() {
      const permisos = await this.getPermissionsTopesCoberturasEspeciales({
        optionCode: this.optionCode
      });
      this.canCreate = permisos.canCreate;
      this.canEdit = permisos.canEdit;
      this.canDelete = permisos.canDelete;
    },
    async setSelects() {
      this.isLoading = true;
      const planes = await this.getPlanesTaxonomy();
      this.planesItems = planes;
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
      const nomencladores = await this.getNomencladores();
      this.nomencladorItems = nomencladores;
      this.isLoading = false;
    },
    async setPlanesByConvenios() {
      if (this.conveniosSelect === undefined) {
        const planes = await this.getPlanesTaxonomy();
        this.planesItems = planes;
      } else {
        const planes = await this.getPlanesByConvenio([
          this.conveniosSelect.id
        ]);
        this.planesItems = planes;
      }
      this.planesItems.unshift({ id: -1, value: "Todos" });
    },
    async loadTopesCoberturaEspecial() {
      if (this.coberturaEspecialObject) {
        this.isLoading = true;
        this.showFilters = false;
        this.coberturaEspecialSelect = this.coberturaEspecialObject.tcobNombre;
        this.customizeFiltersApplied();
        const data = {
          tipoCobId: this.coberturaEspecialObject.idTipocob,
          osId: this.conveniosSelect?.id,
          planId: this.planesSelected ?this.planesSelected.id : -1,
          nomencladorId: this.nomencladorSelected?.id,
          codigo: this.codigoSelect
        };
        const topesPorCobertura = await this.getTopesPorTipoCoberturaEspecial(
          data
        );
        this.paramObjectTope = topesPorCobertura;
        this.itemsTopesPorCoberturas = topesPorCobertura;
        this.isLoading = false;
      } else {
        this.setAlert({
          type: "warning",
          message:
            "Vuelva a la pantalla anterior de tipos de cobertura especial para cargar nuevamente la tabla."
        });
        this.isLoading = false;
      }
    },
    resetForm() {
      // this.$refs["filters-form"].reset();
      this.nomencladorSelected = null;
      this.codigoSelect = null;
      this.conveniosSelect = null;
      this.planesSelected = null;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsTopesPorCoberturas?.forEach(x =>
        result.push({
          ["Convenio"]: x.os.value,
          ["Plan"]: x.plan.value,
          ["Sexo"]: x.sexo,
          ["Edad desde"]: x.edadDesde,
          ["Edad hasta"]: x.edadHasta,
          ["Nomenclador"]: x.nomenclador.value,
          ["Código desde"]: x.codigoDesde,
          ["Código hasta"]: x.codigoHasta,
          ["Agrupación de práctica"]: x.agruPraNombre,
          ["Grupo de práctica"]: x.grupoNombre,
          ["Vigencia"]: x.vigencia,
          ["Topes"]: x.tope,
          ["Días"]: x.dias,
          ["Tipo de autorización"]: x.ta.value
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Topes por cobertura especial");
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.coberturaEspecialSelect) {
        this.filtersApplied.splice(0, 1, {
          key: "coberturaEspecialSelect",
          label: "Cobertura Especial",
          model: this.coberturaEspecialSelect
        });
      }
      if (this.nomencladorSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "nomencladorSelected",
          label: "Nomenclador",
          model: this.nomencladorSelected.value
        });
      }
      if (this.codigoSelect) {
        this.filtersApplied.splice(2, 0, {
          key: "codigoSelect",
          label: "Código de práctica",
          model: this.codigoSelect
        });
      }
      if (this.conveniosSelect) {
        this.filtersApplied.splice(3, 0, {
          key: "conveniosSelect",
          label: "Convenio",
          model: this.conveniosSelect.value
        });
      }
      if (this.planesSelected) {
        this.filtersApplied.splice(4, 0, {
          key: "planesSelected",
          label: "Plan",
          model: this.planesSelected.value
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    deleteTope(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item.tipoCobTopeId;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteTopePorTipoCoberturaEspecial(
          this.itemToDelete
        );
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadTopesCoberturaEspecial();
          this.loadingDeleteBtn = false;
        }
      } catch {
        this.loadingDeleteBtn = false;
        this.showDeleteModal = false;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.TopecoberturaEspecialObj = item;
      this.coberturaObjectNomId = this.coberturaEspecialObject;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadTopesCoberturaEspecial();
    }
  }
};
</script>
