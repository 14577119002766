<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="validarSave()"
        >
          <v-row>
            <!-- Cobertura -->
            <v-col cols="12" class="py-0" md="12">
              <v-text-field
                v-model.trim="coberturaNom"
                type="text"
                outlined
                dense
                clearable
                filled
                readonly
                disabled
                label="Cobertura"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Convenios -->
            <v-col cols="6" md="6" class="py-0">
              <v-autocomplete
                v-model="conveniosSelect"
                :items="conveniosItems"
                label="Convenio"
                item-text="value"
                item-value="id"
                outlined
                dense
                :rules="rules.required"
                @change="setPlanesByConvenios()"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <!-- Planes -->
            <v-col cols="6" md="6" class="py-0">
              <v-autocomplete
                v-model="planesSelected"
                :items="planesItems"
                item-text="value"
                item-value="id"
                label="Plan"
                outlined
                dense
                :rules="rules.required"
                return-object
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Edad desde -->
            <v-col cols="2" class="py-0" md="3">
              <v-text-field
                v-model.trim="edadDesde"
                type="text"
                outlined
                dense
                clearable
                label="Edad desde"
                v-mask="'###'"
                :rules="
                  edadDesde || edadDesde === 0
                    ? rules.requiredAcceptZero.concat(
                        rules.positiveNumber.concat(
                          edadHasta
                            ? [
                                rules.validDateRange(
                                  parseInt(edadDesde),
                                  parseInt(edadHasta)
                                )
                              ]
                            : []
                        )
                      )
                    : ['Campo requerido']
                "
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Edad hasta -->
            <v-col cols="2" class="py-0" md="3">
              <v-text-field
                v-model.trim="edadHasta"
                type="text"
                outlined
                dense
                clearable
                label="Edad hasta"
                v-mask="'###'"
                :rules="
                  edadHasta || edadHasta === 0
                    ? rules.requiredAcceptZero.concat(
                        rules.positiveNumber.concat(
                          edadHasta
                            ? [
                                rules.validDateRange(
                                  parseInt(edadDesde),
                                  parseInt(edadHasta)
                                )
                              ]
                            : []
                        )
                      )
                    : ['Campo requerido']
                "
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Sexo -->
            <v-col cols="4" md="4" class="py-0">
              <v-autocomplete
                v-model="generoSelected"
                :items="generos"
                item-text="value"
                item-value="value"
                label="Sexo"
                :disabled="TopecoberturaEspecialObj != null"
                :rules="rules.required"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-radio-group
                row
                v-model="grupoAndRango"
                mandatory
                class="py-0 mt-2"
              >
                <v-radio
                  class="py-0"
                  label="Rango de códigos"
                  value="R"
                ></v-radio>
                <v-radio
                  class="py-0"
                  label="Grupo de prácticas"
                  value="G"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="grupoAndRango === 'R'">
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-card-title class="pl-0 pt-0"
                ><b>Rango de códigos</b></v-card-title
              >
            </v-col>
          </v-row>
          <section v-if="grupoAndRango === 'R'">
            <v-row class="pt-0">
              <!-- nomenclador -->
              <v-col cols="4" class="py-0">
                <v-autocomplete
                  v-model="tipoNomenclador"
                  :items="itemsTipoNomenclador"
                  label="Tipo nomenclador"
                  return-object
                  item-text="value"
                  item-value="id"
                  outlined
                  clearable
                  dense
                  :rules="
                    grupoAndRango === 'R' && tipoNomenclador == null
                      ? ['Campo requerido']
                      : []
                  "
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <!-- codigo desde -->
              <v-col cols="3" md="4" class="py-0">
                <v-text-field
                  v-model.trim="codigoDesde"
                  label="Código desde"
                  outlined
                  clearable
                  dense
                  v-mask="'#########'"
                  :rules="
                    grupoAndRango === 'R' &&
                    codigoHasta != null &&
                    codigoHasta != ''
                      ? rules.requiredAcceptZero.concat(
                          rules.positiveNumber,
                          parseInt(codigoDesde) <= parseInt(codigoHasta) ||
                            'Rango inválido'
                        )
                      : []
                  "
                  :disabled="tipoNomenclador == null"
                  @change="buscarCodigos(true)"
                ></v-text-field>
              </v-col>
              <!-- Descripcion codigo desde -->
              <v-col cols="7" class="py-0" md="7">
                <v-text-field
                  v-model.trim="descCodigoDesde"
                  type="text"
                  outlined
                  filled
                  dense
                  disabled
                  label="Descripción código desde"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Boton buscar codigo desde -->
              <v-col cols="2" md="1" class="py-0">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      absolute
                      fab
                      small
                      color="primary"
                      :disabled="tipoNomenclador == null"
                      @click="openModalBusquedaCodigos(true)"
                    >
                      <v-icon>{{ searchIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Buscar "código desde" a través de la descripción</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <!-- codigo hasta -->
              <v-col cols="3" md="4" class="py-0">
                <v-text-field
                  v-model.trim="codigoHasta"
                  label="Código hasta"
                  outlined
                  clearable
                  v-mask="'#########'"
                  dense
                  :disabled="tipoNomenclador == null"
                  :rules="
                    grupoAndRango === 'R'
                      ? rules.requiredAcceptZero.concat(
                          rules.positiveNumber,
                          Number(codigoDesde) <= Number(codigoHasta) ||
                            'Rango inválido'
                        )
                      : []
                  "
                  @change="buscarCodigos(false)"
                ></v-text-field>
              </v-col>
              <!-- Descripcion codigo hasta -->
              <v-col cols="7" class="py-0" md="7">
                <v-text-field
                  v-model.trim="descCodigoHasta"
                  type="text"
                  outlined
                  dense
                  filled
                  disabled
                  label="Descripción código hasta"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Boton buscar codigo hasta -->
              <v-col cols="2" md="1" class="py-0">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      absolute
                      fab
                      small
                      color="primary"
                      :disabled="tipoNomenclador == null"
                      @click="openModalBusquedaCodigos(false)"
                    >
                      <v-icon>{{ searchIcon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Buscar "código hasta" a través de la descripción</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </section>
          <v-row v-if="grupoAndRango === 'G'">
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-card-title class="pl-0 pt-0"
                ><b>Grupo de prácticas</b></v-card-title
              >
            </v-col>
          </v-row>
          <v-row v-if="grupoAndRango === 'G'">
            <v-col cols="6" class="py-0 pt-0">
              <v-select
                v-model="agruPracticasSelected"
                :items="agruPracticas"
                label="Agrupación de prácticas"
                return-object
                clearable
                item-text="value"
                item-value="id"
                @change="setGrupos(), clearGrupoPractica()"
                outlined
                :rules="
                  this.grupoAndRango === 'G' && agruPracticasSelected == null
                    ? ['Campo requerido']
                    : []
                "
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0 pt-0">
              <v-autocomplete
                v-model="grupoPracticaSelected"
                :items="itemsGrupoPractica"
                label="Grupo de prácticas"
                return-object
                item-text="value"
                :disabled="agruPracticasSelected == null"
                item-value="id"
                :rules="
                  grupoAndRango === 'G' &&
                  agruPracticasSelected != null &&
                  grupoPracticaSelected == null
                    ? ['Campo requerido']
                    : []
                "
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Fecha vigencia hasta-->
            <v-col cols="4" sm="6" md="4" class="py-0">
              <v-menu
                ref="menu-fecha-vigencia"
                v-model="menuFechaVigencia"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaVigenciaSelected"
                    label="Vigencia hasta"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    clearable
                    autocomplete="off"
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @blur="
                      fechaVigencia = parseDateToIso(fechaVigenciaSelected)
                    "
                    v-on="on"
                    :rules="rules.required.concat(rules.validDate)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVigencia"
                  no-title
                  scrollable
                  @change="fechaVigenciaSelected = formatDate(fechaVigencia)"
                  @input="menuFechaVigencia = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Tope -->
            <v-col cols="4" class="py-0" md="3">
              <v-text-field
                v-model.trim="tope"
                type="text"
                outlined
                dense
                clearable
                label="Tope"
                autocomplete="off"
                v-mask="'###'"
                :rules="
                  tope || tope === 0
                    ? rules.requiredAcceptZero
                    : ['Campo requerido']
                "
              ></v-text-field>
            </v-col>
            <!-- Dias -->
            <v-col cols="4" class="py-0" md="3">
              <v-text-field
                v-model.trim="dias"
                type="text"
                outlined
                dense
                clearable
                label="Días"
                autocomplete="off"
                v-mask="'#####'"
                :rules="
                  dias || dias === 0
                    ? rules.requiredAcceptZero.concat(rules.positiveNumber36500)
                    : ['Campo requerido']
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de autorizacion -->
            <v-col cols="12" md="12" class="py-0">
              <v-autocomplete
                v-model="tipoAutorizacionSelect"
                :items="tiposAutorizacionItems"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                clearable
                dense
                autocomplete="on"
                label="Tipo de autorización"
                return-object
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- Check controlar codigos del rango -->
            <v-col cols="12" md="7" class="py-0">
              <v-switch
                v-model="controlarRango"
                class="my-0"
                :disabled="grupoAndRango === 'G'"
                label="Controlar todos los códigos del rango."
              ></v-switch>
            </v-col>
            <!-- Check tope por año calendario -->
            <v-col cols="12" md="5" class="py-0">
              <v-switch
                v-model="topePorAno"
                class="my-0"
                label="Tope por año calendario."
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
    <!-- modal para buscar codigo desde -->
    <v-dialog
      v-model="modalBusquedaCodigos"
      v-if="modalBusquedaCodigos"
      @keydown.esc="toggleModalBusquedaCodigos"
      max-width="50%"
      persistent
    >
      <BusquedaCodigoNomenclador
        @toggleModalBusquedaCodigo="toggleModalBusquedaCodigos"
        @findCodigo="findCodigo"
        :nomencladorObject="tipoNomenclador"
      ></BusquedaCodigoNomenclador>
    </v-dialog>
    <v-dialog
      v-model="ModalValidacion"
      v-if="ModalValidacion"
      @keydown.esc="closeModalValidacion"
      max-width="40%"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary--text"
          ><span>Advertencia</span></v-card-title
        >
        <v-card-text class="text-left"
          ><span
            ><strong>{{ textValidacion }}</strong></span
          ></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeModalValidacion" :loading="loadingSaveBtn"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="primary"
            dark
            @click="saveEdit"
            :loading="loadingSaveBtn"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import BusquedaCodigoNomenclador from "@/components/modules/afiliaciones/afiliados/BusquedaCodigoNomenclador.vue";
export default {
  directives: { mask },
  name: "EditTopesCoberturaEspecial",
  components: {
    BusquedaCodigoNomenclador
  },
  props: {
    TopecoberturaEspecialObj: {
      type: Object,
      default: null
    },
    coberturaObjectNomId: {
      type: Object,
      default: null
    },
    itemsTopesPorCoberturas: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      rules: rules,
      formEditTitle: enums.titles.EDIT_TOPE_POR_TIPO_COBERTURA,
      newTitle: enums.titles.NUEVO_TOPE_POR_TIPO_COBERTURA,
      calendarIcon: enums.icons.CALENDAR,
      searchIcon: enums.icons.SEARCH,
      coberturaNom: "",
      agruPracticas: [],
      edadDesde: null,
      edadHasta: null,
      codigoDesde: null,
      codigoHasta: null,
      tope: null,
      dias: null,
      textValidacion: "",
      modalBusquedaCodigos: false,
      descCodigoHasta: null,
      descCodigoDesde: null,
      generoSelected: "Ambos",
      generos: [{ value: "Ambos" }, { value: "M" }, { value: "F" }],
      tipoNomenclador: null,
      itemsTipoNomenclador: [],
      conveniosSelect: null,
      conveniosItems: [],
      planesSelected: null,
      planesItems: [],
      tipoAutorizacionSelect: null,
      tiposAutorizacionItems: [],
      menuFechaVigencia: null,
      fechaVigencia: null,
      fechaVigenciaSelected: null,
      controlarRango: false,
      topePorAno: false,
      isFormValid: false,
      loadingSaveBtn: false,
      modalBusquedaCodigoDesde: false,
      modalBusquedaCodigoHasta: false,
      errorFechaVigencia: false,
      grupoAndRango: null,
      grupoPracticaSelected: null,
      agruPracticasSelected: null,
      itemsGrupoPractica: [],
      isFindCodigoDesde: false,
      coberturaId: null,
      idVerTope: null,
      ModalValidacion: false
    };
  },
  computed: {
    controlarRangoComputed() {
      // Para iniciar false en ambos casos
      return this.grupoAndRango == 'G' && this.grupoAndRango == 'R';
    }
  },
   watch: {
    // Cuando cambie grupoAndRango, actualizamos controlarRango con el valor de la propiedad computada
    grupoAndRango(newGrupoAndRango) {
      this.controlarRango = this.controlarRangoComputed;
    }
  },
  mounted() {
    // Inicialmente, configuramos controlarRango según la lógica de controlarRangoComputed
    this.controlarRango = this.controlarRangoComputed;
  },
  async created() {
    await this.setSelects();
    if (this.TopecoberturaEspecialObj) {
      this.setTopeCoberturaEspecial();
    } else {
      this.newTopeCoberturaEspecial();
    }
  },
  methods: {
    ...mapActions({
      getConvenios: "aportes/fetchConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      getPlanesTaxonomy: "configAfiliaciones/getPlanesTaxonomy",
      getTiposAutorizacionesTaxonomy:
        "configAfiliaciones/getTiposAutorizacionesTaxonomy",
      getNomencladores: "afiliaciones/getNomencladores",
      getDescByNomencladorYCodigo: "afiliaciones/getDescByNomencladorYCodigo",
      saveTopePorTipoCoberturaEspecial:
        "afiliaciones/saveTopePorTipoCoberturaEspecial",
      validarTope: "afiliaciones/validarTope",
      getGrupoPorPractica: "analytics/getGrupoPorPractica",
      getAgrupacionesPracticas: "analytics/getAgrupacionesPracticas",
      setAlert: "user/setAlert"
    }),
    toggleModalBusquedaCodigos() {
      this.modalBusquedaCodigos = !this.modalBusquedaCodigos;
    },
    openModalBusquedaCodigos(isCodigoDesde) {
      if (isCodigoDesde) this.isFindCodigoDesde = true;
      else this.isFindCodigoDesde = false;
      this.modalBusquedaCodigos = true;
    },
    findCodigo(codigoSelected) {
      if (this.isFindCodigoDesde) {
        this.codigoDesde = codigoSelected.nomCod;
        this.descCodigoDesde = codigoSelected.nombre;
      } else {
        this.codigoHasta = codigoSelected.nomCod;
        this.descCodigoHasta = codigoSelected.nombre;
      }
    },
    async buscarCodigos(isCodigoDesde) {
      if (isCodigoDesde) {
        if (this.codigoDesde) {
          const data = {
            nomenclador: this.tipoNomenclador.id,
            codigo: this.codigoDesde
          };
          const descripcionCodigoDesde = await this.getDescByNomencladorYCodigo(
            data
          );
          this.descCodigoDesde = descripcionCodigoDesde.nombre;
        } else {
          this.descCodigoDesde = null;
        }
      }
      if (!isCodigoDesde) {
        if (this.codigoHasta) {
          const data = {
            nomenclador: this.tipoNomenclador.id,
            codigo: this.codigoHasta
          };
          const descripcionCodigoHasta = await this.getDescByNomencladorYCodigo(
            data
          );
          this.descCodigoHasta = descripcionCodigoHasta.nombre;
        } else {
          this.descCodigoHasta = null;
        }
      }
    },
    clearGrupoPractica() {
      this.grupoPracticaSelected = null;
    },
    async setGrupos() {
      if (this.agruPracticasSelected != null) {
        const grupo = await this.getGrupoPorPractica(
          this.agruPracticasSelected.id
        );
        this.itemsGrupoPractica = grupo;
      }
    },
    async setTopeCoberturaEspecial() {
      this.TopecoberturaEspecialObj;
      this.coberturaNom = this.coberturaObjectNomId.tcobNombre;
      this.edadDesde = this.TopecoberturaEspecialObj.edadDesde;
      this.edadHasta = this.TopecoberturaEspecialObj.edadHasta;
      this.conveniosSelect = this.TopecoberturaEspecialObj.os;
      this.planesSelected = this.TopecoberturaEspecialObj.plan;
      this.tipoNomenclador =
        this.TopecoberturaEspecialObj.nomenclador.id != 0
          ? this.TopecoberturaEspecialObj.nomenclador
          : null;
      this.codigoDesde = this.TopecoberturaEspecialObj.codigoDesde;
      this.codigoHasta = this.TopecoberturaEspecialObj.codigoHasta;
      (this.descCodigoHasta = this.TopecoberturaEspecialObj.nombreHasta),
        (this.descCodigoDesde = this.TopecoberturaEspecialObj.nombreDesde),
        (this.generoSelected = this.TopecoberturaEspecialObj.sexo);
      this.fechaVigenciaSelected = this.TopecoberturaEspecialObj.vigencia;
      this.fechaVigencia = this.parseDateToIso(
        this.TopecoberturaEspecialObj.vigencia
      );
      this.tope = this.TopecoberturaEspecialObj.tope;
      this.dias = this.TopecoberturaEspecialObj.dias;
      this.tipoAutorizacionSelect = this.TopecoberturaEspecialObj.ta;
      this.controlarRango = this.TopecoberturaEspecialObj.controlarTodoCodRango;
      this.topePorAno = this.TopecoberturaEspecialObj.añoCalendario;
      const agruPra = this.agruPracticas.find(
        y => y.id === this.TopecoberturaEspecialObj.agruPraId
      );
      if (agruPra) {
        this.grupoAndRango = "G";
      } else {
        this.grupoAndRango = "R";
      }
      this.agruPracticasSelected = agruPra ? agruPra : null;
      await this.setGrupos();
      const grupoPra = this.itemsGrupoPractica.find(
        y => y.id === this.TopecoberturaEspecialObj.gruId
      );
      this.grupoPracticaSelected = grupoPra ? grupoPra : null;
      this.setPlanesByConvenios();
    },
    newTopeCoberturaEspecial() {
      this.formEditTitle = this.newTitle;
      this.coberturaNom = this.coberturaObjectNomId.tcobNombre;
    },
    async setSelects() {
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
      this.conveniosItems.unshift({ id: 0, value: "Todos" });
      const planes = await this.getPlanesTaxonomy();
      this.planesItems = planes;
      this.planesItems.unshift({ id: -1, value: "Todos" });
      const tiposAutorizacion = await this.getTiposAutorizacionesTaxonomy();
      this.tiposAutorizacionItems = tiposAutorizacion.filter(objeto => {
        return objeto.id === 2 || objeto.id === 4;
      });
      const nomencladores = await this.getNomencladores();
      this.itemsTipoNomenclador = nomencladores;
      const agrup = await this.getAgrupacionesPracticas();
      this.agruPracticas = agrup;
    },
    async setPlanesByConvenios() {
      if (this.conveniosSelect.id === 0) {
        const planes = await this.getPlanesTaxonomy();
        this.planesItems = planes;
      } else {
        const planes = await this.getPlanesByConvenio([
          this.conveniosSelect.id
        ]);
        this.planesItems = planes;
      }
      this.planesItems.unshift({ id: -1, value: "Todos" });
    },
    async validarSave() {
      const valido = {
        tipoCobTopeId: this.TopecoberturaEspecialObj?.tipoCobTopeId,
        tipoCobId: this.coberturaObjectNomId.idTipocob,
        sexo: this.generoSelected,
        edadDesde: parseInt(this.edadDesde),
        edadHasta: parseInt(this.edadHasta),
        grupoNom:
          this.grupoAndRango === "G" && this.grupoPracticaSelected
            ? this.grupoPracticaSelected.value
            : null,
        agruNom:
          this.grupoAndRango === "G" && this.agruPracticasSelected
            ? parseInt(this.agruPracticasSelected.value)
            : null,
        agruPraId:
          this.grupoAndRango === "G" && this.agruPracticasSelected
            ? parseInt(this.agruPracticasSelected.id)
            : null,
        gruId:
          this.grupoAndRango === "G" && this.grupoPracticaSelected
            ? parseInt(this.grupoPracticaSelected.id)
            : null,
        codigoDesde:
          this.grupoAndRango === "R" ? parseInt(this.codigoDesde) : 0,
        codigoHasta: this.grupoAndRango === "R" ? parseInt(this.codigoHasta) : 0
      };
      const validacion = await this.validarTope(valido);
      if (validacion != "") {
        this.textValidacion = validacion + "¿Desea continuar?";
        this.ModalValidacion = true;
      } else {
        this.saveEdit();
      }
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        tipoCobTopeId: this.TopecoberturaEspecialObj?.tipoCobTopeId,
        idTipoCob: this.coberturaObjectNomId.idTipocob,
        osId: this.conveniosSelect.id,
        sexo: this.generoSelected,
        edadDesde: parseInt(this.edadDesde),
        edadHasta: parseInt(this.edadHasta),
        nomencladorId:
          this.grupoAndRango === "R" ? this.tipoNomenclador.id : null,
        codigoDesde:
          this.grupoAndRango === "R" ? parseInt(this.codigoDesde) : 0,
        codigoHasta:
          this.grupoAndRango === "R" ? parseInt(this.codigoHasta) : 0,
        vigencia: this.fechaVigencia,
        tope: parseInt(this.tope),
        dias: parseInt(this.dias),
        taId: this.tipoAutorizacionSelect.id,
        planId: this.planesSelected.id,
        añoCalendario: this.topePorAno,
        controlarTodoCodRango: this.controlarRango == true ? true : false,
        agruPraId:
          this.grupoAndRango === "G" && this.agruPracticasSelected
            ? parseInt(this.agruPracticasSelected.id)
            : null,
        gruId:
          this.grupoAndRango === "G" && this.grupoPracticaSelected
            ? parseInt(this.grupoPracticaSelected.id)
            : null,
        grupoNombre:
          this.grupoAndRango === "G" && this.grupoPracticaSelected
            ? this.grupoPracticaSelected.value
            : null
      };
      if (this.tipoNomenclador != null) {
        this.grupoAndRango === "R";
      } else {
        this.grupoAndRango === "G";
      }
      // Modal de validacion SEXO, Rango de edad y codigo

      try {
        const res = await this.saveTopePorTipoCoberturaEspecial(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    // changeNomenclador() {
    //   this.codigoDesde = null;
    //   this.descCodigoDesde = null;
    //   this.codigoHasta = null;
    //   this.descCodigoHasta = null;
    // },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    findCodigoDesde(codigoSelected) {
      this.codigoDesde = codigoSelected.nomCod;
      this.descCodigoDesde = codigoSelected.nombre;
    },
    toggleModalBusquedaCodigoDesde() {
      this.modalBusquedaCodigoDesde = !this.modalBusquedaCodigoDesde;
    },
    findCodigoHasta(codigoSelected) {
      this.codigoHasta = codigoSelected.nomCod;
      this.descCodigoHasta = codigoSelected.nombre;
    },
    toggleModalBusquedaCodigoHasta() {
      this.modalBusquedaCodigoHasta = !this.modalBusquedaCodigoHasta;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    closeModalValidacion() {
      this.ModalValidacion = false;
    },
    validarFormatoFechaVigencia() {
      if (this.fechaVigenciaSelected) {
        const formatoFechaRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
        if (!formatoFechaRegex.test(this.fechaVigenciaSelected)) {
          // Mostrar un mensaje de error o realizar alguna acción para indicar el formato incorrecto
          // Por ejemplo, puedes establecer una variable para mostrar un mensaje de error
        }
      }
    }
  }
};
</script>
<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
